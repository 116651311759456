import * as React from "react"
import { Link } from "gatsby"

//import Layout from "../components/Layout"
import Seo from "../components/SEO"
import {
  BrandFacebook,
  BrandInstagram,
  BrandLinkedin,
  BrandTiktok,
} from "tabler-icons-react"
import { colors } from "../assets/icons"

const socialLinks = [
  {
    icon: <BrandInstagram size={24} strokeWidth={1} color={colors.text} />,
    text: "@mmv_advogados",
    url: "https://www.instagram.com/mmv_advogados",
  },
  {
    icon: <BrandTiktok size={24} strokeWidth={1} color={colors.text} />,
    text: "@mmv.advogados",
    url: "https://www.tiktok.com/@mmv.advogados",
  },
  {
    icon: <BrandFacebook size={24} strokeWidth={1} color={colors.text} />,
    text: "MMV Advogados",
    url: "https://www.facebook.com/mmvadvogadoss",
  },
  {
    icon: <BrandLinkedin size={24} strokeWidth={1} color={colors.text} />,
    text: "MMV Advogados",
    url: "https://linkedin.com/company/mmv-advogados",
  },
]

const ContactosPage = () => (
  <>
    <section className="section-hero contactos-page">
      <div className="hero-text">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-xl-6">
              <p className="title">Contactos</p>
            </div>
            <div className="col-lg-8 col-xl-6">
              <div className="info">
                <div className="row">
                  <div className="col-md-8">
                    <p className="subtitle">Morada</p>
                    <p>
                      Rua Dr. Alberto Souto, N 24, 1 Andar, Sala 2 <br />
                      Aveiro, Portugal
                    </p>
                    {/* CONTACTO */}
                    <p className="subtitle">Contacto</p>
                    <p>
                      <a href="tel:915485823">915 485 823</a>{" "}
                      <span className="mini-text">
                        {"(chamada para rede móvel nacional)"}
                      </span>
                      <br />
                      <a href="mailto:geral@mmv-advogados.com">
                        geral@mmv-advogados.com
                      </a>
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p className="subtitle">Redes Sociais</p>
                    <ul className="social-links">
                      {socialLinks.map((item, key) => (
                        <li key={key}>
                          <a href={item.url} target="_blank" rel="noreferrer">
                            {item.icon}
                            <span className="link-text">{item.text}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-image">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3027.3690058866828!2d-8.650333784353474!3d40.64379554972178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd23980fee9baab7%3A0x6aed29469511873d!2sR.%20Dr.%20Alberto%20Souto%2024%201%20Andar%2C%20Sala%202%2C%203800-253%20Aveiro!5e0!3m2!1spt-PT!2spt!4v1668017898879!5m2!1spt-PT!2spt"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          loading="lazy"
        ></iframe>
      </div>
    </section>
  </>
)

export const Head = () => <Seo title="Contactos" />

export default ContactosPage
